const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 改签订单数量查询
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/manage/order/changeRecordCount',
    urlSuffix:'/buyer/dom/manage/changeCount',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
