import _ from 'underscore';
import moment from 'moment';
import Q from 'q';

import flight_domestic_buyer_manage_order_chgList from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_chgList'
import flight_domestic_buyer_manage_order_chgStates
  from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_chgStates'
import flight_domestic_airlines from '@/lib/data-service/flight/flight_domestic_airlines'
// 取消订单
import order_cancel from '@/lib/data-service/flight/flight_domestic_buyer_white_order_cancel'
import scrollTop from '@/lib/common-service/scrollTop'
import changeRecord from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_changeRecordCount'
//import FligthTime from '@/page/distributor/air-ticket/admin/component/flightTimeBox'
import AirlineSelect from '@/page/distributor/air-ticket/components/airlineSelect'
import cancelChangePay from '@/lib/data-service/flight/buyer_dom_white_cancelChangePay'
import ListTimer from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/list-timer'
import plat_dom_getAllProductType from '@/lib/data-service/flight/plat_dom_getAllProductType'
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue'
import plat_dom_getPartProductType from '@/lib/data-service/flight/plat_dom_getPartProductType'
export default {
  components: { AirlineSelect, RefundChangeLabel },
  data() {
    return {
      //timeUpbox: null,
      //timeUpboxHeight: null,
      searchForm: {
        changeOrderNo: '',
        pnr: ``,
        orderNo: ``,
        ticketNumber: ``,

        airCode: ``,
        psgName: ``,
        productType: '',

        appTimeBegin : ``,
        appTimeEnd: ``,
        appTime: [],

        changeStatus: `1`,
      },
      pCurrentPage: 1,
      cancelOrderNo: '',
      dialogVisible: false,
      changeRecordList: [],
      pageResult: {
        currentPage: 1,
        pageSize: 10,
        pageData: [],
        totalCount: 0,
      },

      data_flight_domestic_airlines: {
        pageResult: {
          pageData: [],
        }
      },

      data_flight_domestic_buyer_manage_order_chgStates: {
        pageResult: {
          pageData: [],
        }
      },
      NavItemActive: 0,
      loading: true,

      deferInitDataCompleted: '',
      deferResolve: '',
      aListTimer: null,

      productTypeList: [],
      selectProductTypeList: [
        /*{ label: '全部', value: '' },
        { label: '平台优选', value: '1' },
        { label: '官网', value: '2' },
        { label: '低价推荐', value: '3' }*/
      ],
    };
  },
  computed: {
    // orderTimeStart: {
    //   get() {
    //     const __this = this;
    //     const flag = moment(__this.searchForm.__orderTimeStart).isValid();
    //     let s = ``;
    //     if (flag) {
    //       const d = moment(__this.searchForm.__orderTimeStart);
    //       s = d.format(`YYYY/MM/DD`);
    //     }
    //     return s;
    //   }
    // },
    orderTimeEnd: {
      get() {
        const __this = this;
        const flag = moment(__this.searchForm.__orderTimeEnd).isValid();
        let s = ``;
        if (flag) {
          const d = moment(__this.searchForm.__orderTimeEnd);
          s = d.format(`YYYY/MM/DD`);
        }
        return s;
      }
    },
  },
  methods: {
    getPartProductType() {
      plat_dom_getPartProductType().then(res => {
        let selectProductTypeList = res.producyTypes
        selectProductTypeList.unshift({
          code: '',
          msg: '全部'
        })
        this.selectProductTypeList = selectProductTypeList
      })
    },
    async __data_bind_list(pParameter) {
      const __this = this;
      if (!pParameter) pParameter = {};
      const currentPage = pParameter.currentPage || __this.pageResult.currentPage;
      const pageSize = pParameter.pageSize || __this.pageResult.pageSize;

      const pnr = __this.searchForm.pnr;
      const changeOrderNo = __this.searchForm.changeOrderNo;
      const orderNo = __this.searchForm.orderNo;
      const ticketNumber = __this.searchForm.ticketNumber;
      const airCode = __this.searchForm.airCode;
      const psgName = __this.searchForm.psgName;
      const changeStatus = __this.searchForm.changeStatus;
      let _fromData = this.searchForm
      const appTimeBegin = __this.searchForm.appTimeBegin;
      const appTimeEnd = __this.searchForm.appTimeEnd;
      const productType = __this.searchForm.productType
      let data = {
        currentPage: currentPage,
        pageSize: pageSize,
        pnr: pnr,
        orderNo: orderNo,
        ticketNumber: ticketNumber,
        airCode: airCode,
        psgName: psgName,
        changeStatus: changeStatus,
        appTimeBegin,
        appTimeEnd,
        flightNo: _fromData.flightNo,
        contactTel: _fromData.contactTel,
        psgCardNo: _fromData.psgCardNo,
        changeOrderNo: changeOrderNo,
        productType
      }
      __this.loading = true;
      flight_domestic_buyer_manage_order_chgList(data).then(res => {
        let pageData = res.pageResult.pageData || [];
        pageData.forEach(value => {
          if (value.changeEfficiency === '' || value.changeEfficiency === null) {
            value.ticketEfficiencyResult = ''
          } else if (value.ticketEfficiencyStatus === 3) {
            value.ticketEfficiencyResult = value.changeEfficiency
          } else if (value.ticketEfficiencyStatus === 2) {
            value.ticketEfficiencyResult = value.changeEfficiency
          } else if (value.ticketEfficiencyStatus === 1) {
            value.ticketEfficiencyResult = ''
          }
        })
        __this.pageResult.pageData = pageData;
        __this.pageResult.totalCount = res.pageResult.totalCount
        __this.loading = false;
        this.deferResolve()
        this._changeRecord(data)
        scrollTop(500)
      }).catch(error => {
        __this.pageResult.pageData = []
        __this.pageResult.totalCount = 0
        __this.loading = false;
      })

    },
    cancelFilling(val) {
      this.$confirm('确定取消改签吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelChangePay({ changeNo: val.changeOrderNo, orderNo: val.orderNo }).then(res => {
          console.log(res)
          this.$message({ type: 'success', message: "取消改签成功！" })
        })
      }).catch(() => {
      });
    },
    toRefund(val) {
      let psgType
      if (val.passengerList) {
        let psg = val.passengerList.map(item => {
          return item.psgType
        })
        if (psg.indexOf(1) !== -1) {
          psgType = 1
        } else {
          psgType = 2
        }
      }
      this.$router.push({
        name: 'air-ticket-admin-dishonour',
        query: {
          ordersNo: val.orderNo,
          psgType
        }
      })
    },
    __el_pagination_current_change_event_handler(pCurrentPage) {
      const __this = this;
      this.pCurrentPage = pCurrentPage
      __this.__data_bind_list({
        currentPage: pCurrentPage,
      });
    },
    _changeRecord(data) {
      changeRecord(data).then(res => {
        this.changeRecordList = res.records
      })
    },
    pay(val) {
      console.log(val)
      let psgNoList = val.passengerList.map(item => {
        return item.psgNo
      })
      this.$router.push({
        name: 'air-chang-payment',
        query: {
          ordersNo: val.changeOrderNo,
          psgNoList,
          pay: '12',
        },
        // params: {
        //   refresh: true
        // }
      })
    },
    setOrdersNo(ordersNo, toString, psgList) {
      // if (type == 'pay') {
      //   return this.$router.push({ name: toString, query: { ordersNo: ordersNo, pay: '12' } })
      // }
      let psgType, psgNo

      if (psgList) {
        let psg = psgList.map(item => {
          return item.psgType
        })
        psgNo = psgList.map(item => {
          return item.psgNo
        })
        if (psg.indexOf(1) != -1) {
          psgType = 1
        } else {
          psgType = 2
        }
      }
      this.$router.push({
        name: toString,
        query: {
          ordersNo,
          psgType,
          psgNo: JSON.stringify(psgNo),
          orderType: 'change'
        }
      })
    },
    toQingsongfuPay(orders, toString) {
      let passengerNoList = []
      orders.passengerList.forEach(value => {
        passengerNoList.push(value.psgNo)
      })
      this.$router.push({
        name: toString,
        query: {
          bizOrderNo: orders.changeOrderNo,
          orderNo: orders.orderNo,
          passengerNoList: JSON.stringify(passengerNoList),
        }
      })
    },
    // offHeight(e) {
    //   this.timeUpboxHeight = e.target.offsetParent.offsetHeight
    //   // this.timeUpboxHeight = e.fromElement.localName == 'td' ? e.fromElement.offsetHeight : 0
    // },
    cancel(ordersNo) {
      let parame = {
        orderNo: ordersNo,
        remark: ''
      }
      order_cancel(parame).then(res => {
        res.isSuccess ?
          this.$message({ type: "warning", message: "操作成功！" }) : ''
        this.__data_bind_list({ currentPage: this.pCurrentPage })
      })
    },

    __el_pagination_size_change_event_handler(pPageSize) {
      const __this = this;
      __this.pageResult.pageSize = pPageSize
      __this.__data_bind_list();
    },
    __changeAppTime(val) {
      const __this = this;
      if (val === '' || val === null || val.length === 0) {
        __this.searchForm.appTimeBegin = ''
        __this.searchForm.appTimeEnd = ''
      } else {
        __this.searchForm.appTimeBegin = val[0] + ' 00:00:00'
        __this.searchForm.appTimeEnd = val[1] + ' 23:59:59'
      }
      __this.__btn_search_click()
    },
    __btn_search_click(val) {
      const __this = this;
      __this.__data_bind_list({ currentPage: 1 });
    },

    __btn_reset_click() {
      const __this = this;

      _.each(__this.searchForm, function (property_value, property_name) {
        __this.searchForm[property_name] = ``;
      });
      __this.searchForm.changeStatus = `1`;

      __this.__data_bind_list();
    },

    __btn_tab_click(pItem, pIndex) {
      const __this = this;
      __this.searchForm.changeStatus = pItem.status;
      __this.NavItemActive = pIndex;
      this.pageResult.currentPage = 1
      __this.__btn_search_click();
    },
    _initTimer() {
      const __this = this
      let deferInitDataCompleted = this.deferInitDataCompleted
      deferInitDataCompleted.then(res => {
        const aListTimer = new ListTimer().init({
          interval: `minute`,
          get_data_source() {
            const entity_list = __this.pageResult.pageData;

            const d = {
              entity_list,
            };
            return Q.resolve(d);
          },
          handler(args) {
            const entity = args.entity;
            const index_entity = args.index_entity;
            const index_setInterval = args.index_setInterval
            const utils = args.utils;
            const get_process_data_101 = utils.get_process_data_101
            if (index_setInterval === 0) {
              return
            }
            if (entity.changeEfficiency === '' || entity.changeEfficiency === null) {
              entity.ticketEfficiencyResult = ''
            } else if (entity.ticketEfficiencyStatus === 3) {
              entity.ticketEfficiencyResult = entity.changeEfficiency
            } else if (entity.ticketEfficiencyStatus === 2) {
              entity.ticketEfficiencyResult++
            } else if (entity.ticketEfficiencyStatus === 1) {
              entity.ticketEfficiencyResult = ''
            }
            //entity.custom_value_001 = entity.custom_value_001 + 1;

            // const value_101 = entity.value_101;
            //
            // const process_data_101 = get_process_data_101({
            //   date_time_format_string: value_101,
            // });
            // let custom_value_101;
            // if (process_data_101.diff_now >= 0) {
            //   custom_value_101 = `距现在：${process_data_101.format_string}`;
            // } else {
            //   custom_value_101 = `已过去：${process_data_101.format_string}`;
            // }
            // entity.custom_value_101 = custom_value_101;

            // console.log(JSON.stringify(entity));
          },
        });

        __this.aListTimer = aListTimer
      })
    },
    async getAllProductType() {
      let [err, res] = await awaitWrap(plat_dom_getAllProductType())
      console.log(res)
      if (err) {
        return
      }
      let productTypeList = res.producyTypes
      productTypeList.unshift({
        code: '',
        msg: '全部'
      })
      this.productTypeList = productTypeList
    },
    setTagType(val) {
      let type = ''
      const typeList = ['policy-label-1', 'policy-label-2', 'policy-label-3', 'policy-label-4', 'policy-label-5', 'policy-label-6']
      this.productTypeList.forEach((value, index) => {
        const codeList = value.code.split(',')
        if (codeList.indexOf(val) >= 0) {
          type = typeList[index]
        }
      })
      return type
    },
  },
  watch: {
    '$route.query.ordersNo': function (newId) {
      this.__data_bind_list()
    }
  },
  created() {
    this.deferInitDataCompleted = new Promise((resolve, reject) => {
      this.deferResolve = resolve
    })
  },
  async mounted() {
    const __this = this;

    const data_flight_domestic_airlines = await flight_domestic_airlines();
    __this.data_flight_domestic_airlines = data_flight_domestic_airlines;
    this.getPartProductType()
    await this.getAllProductType();

    // const data_flight_domestic_buyer_manage_order_chgStates = await flight_domestic_buyer_manage_order_chgStates();
    // __this.data_flight_domestic_buyer_manage_order_chgStates = data_flight_domestic_buyer_manage_order_chgStates;

    __this.__data_bind_list();
    __this._initTimer()
  },
  activated() {
  },
  deactivated() {
    if (this.aListTimer) {
      this.aListTimer.clear()
    }
  },
  destroyed() {
  },
}
